// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { TitleH1 } from 'components/Common/Typography'
import { FC, useCallback } from 'react'
import { Container, Button } from 'react-bootstrap'
import ReactTypingEffect from 'react-typing-effect'
import Link from 'next/link'
import Image from 'next/image'
import LinkButton from 'components/LinkButton'
import { useEffect, useState } from 'react'
import BannerSlider from './BannerSliders'
import BannerSliderBot from './BannerSlidersBot'



const HomeBannerAnimate: FC = () => {
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <section className='home_banner_ani'>
      <section className="home_banner_ani_inner">
      <Container>
        <div className="align-items-center row">
          <div className="col-xl-7">
            <TitleH1 text="Empowering Businesses Globally With">
              <ReactTypingEffect
                text={[
                  'Digital Transformation',
                  'Technology Consulting',
                  'Intelligence Automation',
                ]}
                speed={50}
                eraseSpeed={30}
                className="typing_text"
              />
            </TitleH1>

            <p
              className="text_para text_para_large text_para_home mb-5 text-white text-lg"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              We integrate cutting-edge technology into your business processes
              to propel your enterprise to unprecedented heights on a global
              scale.
            </p>
            <LinkButton
              path="/contact-us"
              className="text-decoration-none"
              tabIndex={0}
              dataAos="fade-up"
              dataAosDelay="400"
            >
              <span>Get in Touch</span>
            </LinkButton>
          </div>
        </div>
      </Container> </section>
      {isMobile ? (
    <>
     <BannerSlider />
     <BannerSliderBot />
    </>
    ) : (
      <div className="mainCircleOut">
        <div className="mainCircle">
          <div className="big-circle">
            <div className="icon-block">
              <Image
                src="/assets/images/cr01.svg"
                alt="Binance"
                width={48}
                height={48}
              />
            </div>
            <div className="icon-block"></div>
            <div className="icon-block">
              <Image
                src="/assets/images/cr02.svg"
                alt="Binance"
                width={48}
                height={48}
              />
            </div>
            <div className="icon-block"></div>
            <div className="icon-block">
              <Image
                src="/assets/images/cr03.svg"
                alt="Javascript"
                width={48}
                height={48}
              />
            </div>
            <div className="icon-block"></div>
            <div className="icon-block">
              <Image
                src="/assets/images/cr04.svg"
                alt="Opencart"
                width={48}
                height={48}
              />
            </div>
            <div className="icon-block"></div>
            <div className="icon-block">
              <Image
                src="/assets/images/cr05.svg"
                alt="React"
                width={48}
                height={48}
              />
            </div>
            <div className="icon-block"></div>
          </div>
          <div className="circle">
            <div className="icon-block">
              <Image
                src="/assets/images/cr06.svg"
                alt="Python"
                width={48}
                height={48}
              />
            </div>
            <div className="icon-block"></div>
            <div className="icon-block">
              <Image
                src="/assets/images/cr07.svg"
                alt="Swift"
                width={48}
                height={48}
              />
            </div>
            <div className="icon-block"></div>
            <div className="icon-block">
              <Image
                src="/assets/images/cr08.svg"
                alt="Shopify"
                width={48}
                height={48}
              />
            </div>
            <div className="icon-block"></div>
            <div className="icon-block">
              <Image
                src="/assets/images/cr09.svg"
                alt="Shopify"
                width={48}
                height={48}
              />
            </div>
            <div className="icon-block"></div>
          </div>
          <div className="center-logo">
            <Image
              src="/assets/images/cr_logo.svg"
              alt="logo"
              width={259}
              height={197}
            />
          </div>
        </div>
      </div>
       )}
   </section>
    
  )
}

export default HomeBannerAnimate
