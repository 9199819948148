import React, { FC } from 'react'
import Image from 'next/image'
import Slider from 'react-slick'

const settings = {
  dots: false,
  infinite: true,
  speed: 10000, 
  slidesToShow: 3, 
  slidesToScroll: 1, 
  arrows: false, 
  autoplay: true,
  autoplaySpeed: 0, 
  cssEase: 'linear', 
  pauseOnHover: false, 
  pauseOnFocus: false, 
  rtl:true,
  //  
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 1,
      },
    },
  ],
}

const BannerSliderBot: FC = () => {
  return (
          <section className='mob-banner-slider mob-banner-slider01 mt-4'>
            <Slider {...settings}>
              <div className='mob-cir-slide'>
                <div className='mob-cir-block'>
                <Image
                  src="/assets/images/cr06.svg"
                  alt="React"
                  width={48}
                  height={48}
                />
              </div>
              </div>
               <div className='mob-cir-slide'>
                <div className='mob-cir-block'>
                <Image
                  src="/assets/images/cr07.svg"
                  alt="React"
                  width={48}
                  height={48}
                />
              </div>
              </div>
               <div className='mob-cir-slide'>
                <div className='mob-cir-block'>
                <Image
                  src="/assets/images/cr08.svg"
                  alt="React"
                  width={48}
                  height={48}
                />
              </div>
              </div>
               <div className='mob-cir-slide'>
                <div className='mob-cir-block'>
                <Image
                  src="/assets/images/cr09.svg"
                  alt="React"
                  width={48}
                  height={48}
                />
              </div>
              </div>
              <div className='mob-cir-slide'>
                <div className='mob-cir-block'>
                <Image
                  src="/assets/images/cr06.svg"
                  alt="React"
                  width={48}
                  height={48}
                />
              </div>
              </div>
               <div className='mob-cir-slide'>
                <div className='mob-cir-block'>
                <Image
                  src="/assets/images/cr07.svg"
                  alt="React"
                  width={48}
                  height={48}
                />
              </div>
              </div>
               <div className='mob-cir-slide'>
                <div className='mob-cir-block'>
                <Image
                  src="/assets/images/cr08.svg"
                  alt="React"
                  width={48}
                  height={48}
                />
              </div>
              </div>
               <div className='mob-cir-slide'>
                <div className='mob-cir-block'>
                <Image
                  src="/assets/images/cr09.svg"
                  alt="React"
                  width={48}
                  height={48}
                />
              </div>
              </div>
            </Slider>
            </section>
  )
}

export default BannerSliderBot
